import React from 'react';
import { Container, Row, Col, Breadcrumb} from "react-bootstrap";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Post } from "../../components/Core";
import DataTable from '../../components/data/banquePro/banquePro'
import DataTableMobile from '../../components/data/banquePro/banqueProMobile'
import Disclosure from '../../components/Disclosure'
import {StaticImage} from 'gatsby-plugin-image';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUser, faUsers, faCity} from '@fortawesome/free-solid-svg-icons'

function Accounting(props) {

	var d = new Date();
var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
var n = month[d.getMonth()];
var y = d.getFullYear();

    return (
        <PageWrapper footerDark>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Banque pour SAS - Meilleure banque pro pour votre SAS ou SASU</title>
                <meta name="description" content="Vous avez une société de type SAS ou SASU? Trouvez la meilleure banque pro avec bankbank." />
            </Helmet>
          <Header/>
          <Container fluid style={{background: 'white', marginTop: 68, paddingTop: 20, paddingBottom: 130}}>
          <Container style={{maxWidth: 1280, marginTop: 28}}>
         
          <Breadcrumb>
            <Breadcrumb.Item> <Link to={`/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Accueil</Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'rgb(41, 49, 61)', fontWeight: 300}}>Banque Pro</Link></Breadcrumb.Item>
            <Breadcrumb.Item active style={{ color: 'rgb(189 190 191)', fontWeight: 300}}>Banque Pro Pour Une SAS (SASU)</Breadcrumb.Item>
          </Breadcrumb>
  

          <h1 id='compareh1' style={{color: 'rgb(41, 49, 61)'}}>Banque pro pour une SAS (SASU)</h1>
          <h2 id='compareh2' style={{color: 'rgb(41, 49, 61)'}}>Trouvez la meilleure banque pro avec un comparatif tarifs, cartes bancaires et comptabilité intégrée (mise à jour {n} {y})</h2>
          <div id='onetwothreemobile'>
          <p id='onep' style={{color: 'rgb(41, 49, 61)'}}><span id='one' style={{background: 'rgb(229 34 79)', paddingLeft: 10, paddingRight: 10, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>1</span> - Utilisez le menu de notre <span style={{fontWeight: 700}}>comparateur banques pro pour SAS</span> pour déterminer vos priorités : employé.e.s, tarifs, services inclus.</p>
          <p id='twop' style={{color: 'rgb(41, 49, 61)'}}><span id='two' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>2</span> - Comparez les offres par tarif de carte bancaire, et les offres promos disponibles dès aujourd’hui.</p>
          <p id='threep' style={{color: 'rgb(41, 49, 61)'}}><span id='three' style={{background: 'rgb(229 34 79)', paddingLeft: 8, paddingRight: 9, paddingTop:3, paddingBottom:3, borderRadius: 3, color: 'white'}}>3</span> - Sélectionnez la <span style={{fontWeight: 700}}>meilleure banque pro</span>, celle qui correspond le plus à vos besoins.</p>
          </div>

          <Disclosure/>
          <Row style={{height: 70, marginTop: -7, marginBottom: -50}}>
          
          </Row>
          </Container>
          </Container>  


     
          <Container style={{maxWidth: 1280, marginTop: -130}}>
        
          <Tabs style={{maxWidth: 1234}}>
    <TabList style={{marginLeft: 5}}>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUser} style={{marginTop: 0, marginBottom: 0, fontSize: 20, marginLeft: 5, marginRight: 10}}/> Société avec 1 employé.e </Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faUsers} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 2 - 5 employé.e.s</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faBuilding} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> 6 - 50 employé.e.s</Tab>
      <Tab style={{marginLeft: 0, textAlign: 'center'}}><FontAwesomeIcon icon={faCity} style={{marginTop: 0, marginBottom: -3, fontSize: 24, marginLeft: 5, marginRight: 10}}/> Plus de 50 employé.e.s</Tab>
    </TabList>

    <TabPanel>
      <DataTable type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='wideTable'/>
      <DataTableMobile type='Société' row={[35,15,2,16,36,18,30,9,38,40]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>      
      <DataTable type='Société' row={[12,4,18,9,19,30,20]} id='wideTable'/>
      <DataTableMobile type='Société' row={[12,4,18,9,19,30,20]} id='mobileTable'/>
    </TabPanel>
      <TabPanel>    
      <DataTable type='Société' row={[9,21,4,22,23,31]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,4,22,23,31]} id='mobileTable'/>
    </TabPanel>
    <TabPanel>
      <DataTable type='Société' row={[9,21,5,22,23,24,25,26]} id='wideTable'/>
      <DataTableMobile type='Société' row={[9,21,5,22,23,24,25,26]} id='mobileTable'/>
    </TabPanel>

  </Tabs>
            <Row className="justify-content-center">
              <Col>
            
       
            

       
              </Col>
            </Row>
             
          </Container>
          <Container style={{marginTop: 100, marginBottom : 100}}>
          <Post>
          <h2>Comment choisir la meilleure banque pour votre SAS ou SASU ?</h2>
      

{/* <p style={{marginTop: 50}}>
    <iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1127930317&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" width="100%" height="150" frameborder="no" scrolling="no" title='website builders'></iframe>
      </p>  */}
      
<p>
Si il s’agit d’une création, vous avez probablement choisit le statut SAS et SASU pour votre entreprise pour vous permettre de grandir en toute liberté.
</p><p>
Attention, cette liberté peut partir en fumée si vous vous associez à une banque pro trop rigide, coûteuse et qui n’offre pas les services permettant une gestion quotidienne simplifiée de votre société.
</p>
<StaticImage
		  	src='../../images/meilleure-banque-pro-sas-sasu.jpeg' width={1200}
			alt='meilleure banque pour sasu'
			placeholder='tracedSVG'
			/>

<p>
Dans un premier temps il est important de déterminer quels types d’opérations bancaires vous allez avoir besoin. Et ces opérations dépendant de la gestion de votre entreprise aujourd’hui ainsi que dans son future proche.
</p>
<h3>Quelle est la taille de vos transactions ?</h3>
<p>
On peut naturellement penser que si vous avez des fonds disponibles, vous pourrez les dépenser.. Ce n’est pas le cas ! Les plafonds de votre banque peuvent paralyser votre activité.
</p><p>
Avez-vous régulièrement besoin de faire des transactions avec des montants élevés ?
</p>

<h3>Règle numéro 1 d’une entreprise : être payé !</h3>
<ul>
<li>Avez-vous besoin de déposer de l’espèce ?</li>
<li>Avez-vous besoin d’encaisser des chèques ?
</li>
<li>Avez-vous besoin d’un terminal de paiement ?</li>
<li>Recevez-vous des paiements sur votre site internet / eCommerce ?</li>
</ul>

<h3>Vous et vos collaborateurs</h3>
<p>Le paiement par carte bancaire de vous ou votre équipe impactera votre entreprise de 2 manières :</p>
<ul>
  <li>Vous devez garder le contrôle sur les dépenses</li>
  <li>Chaque dépense aura besoin d’un justificatif pour la comptabilité</li>
</ul>
<p>
Avez-vous besoin de donner des cartes de paiements à vos collaborateurs ?</p>
<p>
Selon la banque, chaque carte bancaire supplémentaire peut avoir des frais de fabrication ainsi que des coûts de gestion. Certaines offres proposent des packs multi-cartes ou encore des accès à des cartes virtuelles.
</p><p>
Avez-vous ou vos collaborateurs beaucoup de notes de frais ?
</p>

<h3>Comptabilité</h3>
<ul>
  <li>Allez-vous avoir besoin de faire des augmentations de capital ?</li>
  <li>Souhaitez-vous avoir l’option de donner un accès direct à vos comptes à votre comptable ?</li>
</ul>


<h3>Quelle est votre relation avec l’international ?</h3>
<p>
C’est surtout au niveau des voyages d’affaires hors de la zone Euro que l’addition peut être salé si vous ne choisissez pas la meilleure banque.
</p>
<ul>
  <li>Faites-vous beaucoup de virement en euros ?</li>
  <li>Avez-vous besoin de faire beaucoup de virement en devises ?</li>
  <li>Votre équipe voyage t-elle hors de la zone euro pour le business ?</li>
</ul>





</Post>

          </Container>
          <Footer/>
      </PageWrapper>
    )
}

export default Accounting
